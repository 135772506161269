<template>
  <div style="background-color: white">
    <van-sticky :offset-top="50">
      <van-cell size="large">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-row justify="space-around">
            <span class="custom-title">{{
              collectionChapterContent.title
            }}</span>
          </van-row>
        </template>

        <template #label>
          <van-tag style="margin-left: 5px" plain size="medium" type="primary"
            >🙅 {{ collectionChapterContent.author }}</van-tag
          >
          <van-tag style="margin-left: 10px" plain size="medium" type="warning">
            🕜 {{ collectionChapterContent.createTime }}</van-tag
          >
        </template>
      </van-cell>
    </van-sticky>
    <div v-if="collectionChapterContent.videoUrl !== '0'">
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
      </van-divider>
      <van-row type="flex" justify="center">
        <van-col span="22">
          <iframe
            :src="collectionChapterContent.videoUrl"
            width="100%"
            height="220"
            scrolling="no"
            border="0"
            frameborder="no"
            framespacing="0"
            allowfullscreen="true"
          >
          </iframe>
        </van-col>
      </van-row>
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        视频文档
      </van-divider>
    </div>
    <van-row type="flex" justify="center">
      <van-col span="22">
        <div
          v-if="collectionChapterContent.content != null && collectionChapterContent.content != ''"
          class="markdown-body"
        >
          <viewer
            ref="markdownViewer"
            :options="viewerOptions"
            :height="'auto'"
            :initialValue="collectionChapterContent.content"
          ></viewer>
        </div>
        <div v-else>
          <van-empty description="文档整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      参与讨论
    </van-divider>
    <van-row type="flex" justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>
  </div>
</template>

<script>
import {
  ImagePreview,
  Dialog,
  ShareSheet,
  Sticky,
  Divider,
  Empty,
  Cell,
  Tag,
  Col,
  Row,
  Icon,
} from "vant";
import Valine from "./Valine.vue";

import "prismjs/themes/prism.css";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import Prism from "prismjs";
import "prismjs/components/prism-go.js";
import "@toast-ui/editor/dist/i18n/zh-cn";
import "./toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";

export default {
  props: {
    collectionChapterContent: {
      type: Object,
      required: true,
    },
  },
  watch: {
    collectionChapterContent: {
      handler: function (newVal, oldVal) {
        console.log(
          "collectionChapterContent changed from",
          oldVal,
          "to",
          newVal
        );
        this.valineOptions.path =
          "/collection/chapter/" + this.collectionChapterContent.id + ".html";
        this.$nextTick(() => {
          this.$refs.markdownViewer.invoke(
            "setMarkdown",
            this.collectionChapterContent.content
          );
        });
      },
      deep: true,
    },
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    viewer: Viewer,
    [Sticky.name]: Sticky,
    [Divider.name]: Divider,

    Valine,
    [Empty.name]: Empty,

    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
  },

  data() {
    return {
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
      },

      valineOptions: {
        appId: "kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz",
        appKey: "nscorEe7TaRyn1DBFNicKsWH",
        placeholder:
          "欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。",
        path: window.location.pathname,
      },
    };
  },
  //初始化评论
  mounted() {
    this.valineOptions.path =
      "/collection/chapter/" + this.collectionChapterContent.id + ".html";
  },
  methods: {},
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
