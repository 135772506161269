<template>
  <div>
    <van-nav-bar fixed
                 placeholder
                 :title="collectionDetail.title"
                 right-text="分享"
                 @click-right="onClickShare"
                 left-arrow
                 @click-left="onClickLeft" />

    <van-row style="background-color: white">
      <van-col span="9">
        <div style="
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-left: 10px;
          ">
          <van-image width="120px"
                     height="160px"
                     fit="cover"
                     :src="collectionDetail.coverUrl" />
        </div>
      </van-col>
      <van-col span="15">
        <van-cell-group>
          <van-cell size="large">
            <template #title>
              <van-row justify="space-around">
                <van-col span="20">
                  <van-tag size="medium"
                           type="primary">{{
                    collectionDetail.tag
                  }}</van-tag>
                  <span class="custom-title">
                    {{ collectionDetail.title }}</span>
                </van-col>
              </van-row>
            </template>

            <template #label>
              <div class="collection_desc">
                {{ collectionDetail.brief }}
              </div>
            </template>
          </van-cell>
          <van-cell>
            <van-tag style="margin-left: 5px"
                     plain
                     size="medium"
                     type="primary">🙅 {{ collectionDetail.author }}</van-tag>
            <van-tag style="margin-left: 10px"
                     plain
                     size="medium"
                     type="warning"
                     v-if="
                collectionDetail.subscribeStatus == 1 ||
                collectionDetail.subscribeStatus == 3
              ">
              已订阅</van-tag>
            <van-tag style="margin-left: 10px"
                     size="medium"
                     type="warning"
                     @click="subscribeCollection"
                     v-if="
                collectionDetail.subscribeStatus == 0 ||
                collectionDetail.subscribeStatus == 2 ||
                collectionDetail.subscribeStatus == 4
              ">
              订阅
            </van-tag>
          </van-cell>
        </van-cell-group></van-col>
    </van-row>

    <van-tabs>
      <van-tab title="目录">
        <van-cell-group style="margin-top: 10px"
                        inset
                        v-for="(item, index) in collectionDetail.collectionContentsVO"
                        :key="index">
          <van-cell :title="item.title"
                    size="large"  @click="goto(item.chapterId)" > </van-cell>

          <van-cell v-for="(chapter, chapterIndex) in item.children"
                    :key="chapterIndex"
                    @click="goto(chapter.chapterId)"
                    >
            <template #title>
              <div><van-icon name="notes-o" /> {{ chapter.title }}</div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-tab>
      <van-tab title="评价">
        <div style="background-color: white">
          <br />
          <van-row type="flex"
                   justify="center">
            <van-col span="22">
              <Valine :options="valineOptions" />
            </van-col>
          </van-row>
        </div>
      </van-tab>
    </van-tabs>

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

    <van-dialog v-model="buyShow"
                :title="collectionDetail.title"
                cancelButtonText="办理会员"
                confirmButtonText="立即订阅"
                show-cancel-button
                :beforeClose="beforeCloseFn">
      <div style="text-align: center; margin-top: 10px; margin-bottom: 10px">
        <van-image width="200"
                   height="200"
                   :src="collectionDetail.wdImg" />
        <van-notice-bar left-icon="volume-o"
                        text="年度会员可免费订阅。" />
        <van-field required
                   maxlength="12"
                   v-model="coupon"
                   label="激活码："
                   placeholder="请输入合集激活码" />
      </div>
    </van-dialog>

    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="chapterShow">

      <collection-chapter-content get-container="#app"
                    :collectionChapterContent="collectionChapterContent" />

    </van-popup>

  </div>
</template>

<script>
import {
  NoticeBar,
  Toast,
  Field,
  ImagePreview,
  ShareSheet,
  Dialog,
  Image as VanImage,
  Card,
  Tag,
  Tab,
  Tabs,
  Popup,
  NavBar,
  Button,
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
} from "vant";
import Valine from "../Valine.vue";
import CollectionChapterContent from '../../components/CollectionChapterContent.vue';
const axios = require("axios");

export default {
  components: {
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    Valine,
    [Tag.name] : Tag,
    CollectionChapterContent,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data () {
    return {
      chapterShow: false,
      collectionChapterContent: {
        "id": 27,
        "author": "码神之路",
        "content": "",
        "createTime": "2023-06-04",                       
        "status": 1,
        "title": "【最新go入门教程】02-为什么要使用go",        
        "videoUrl": '0'
      },

      buyShow: false,
      coupon: "",
      showShare: false,
      shareOptions: [
        { name: "微信", icon: "wechat" },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        {
          name: "分享海报",
          icon: "https://img01.yzcdn.cn/vant/custom-icon-light.png",
        },
        // { name: '二维码', icon: 'qrcode' },
      ],
      id: 1,
      collectionDetail: {
        title: "《Go语言教程》",
        brief:
          " 实战从零构建一个论坛 AI, redis, mysql, sqlitPI, redis, mysql, sqlite, email, jwt.实战从零构建一个论坛 API, redis, mysql, sqlite, email, jwt.",
        tag: "推荐",
        auhtor: "码神之路",
        coverUrl: "https://image-1302243118.cos.ap-beijing.myqcloud.com/collection/gorumen.jpg",
        createTime: "2023-06-08",
        subscribeStatus: 0,
        collectionChapters: [
          {
            chapterName: "第一章：基础信息",
            collectionChapterRelations: [
              {
                titleAlias: "1.1 序言",
                url: "https://m.golangroadmap.com/#/sharevideo?id=27",
              },
              {
                titleAlias: "1.2 如何阅读本书",
                url: "https://m.golangroadmap.com/#/sharevideo?id=28",
              },
            ],
          },
          {
            chapterName: "第二章：进阶",
            collectionChapterRelations: [
              {
                titleAlias: "2.1 序言",
                url: "https://m.golangroadmap.com/#/sharevideo?id=29",
              },
              {
                titleAlias: "2.2 如何阅读本书",
                url: "https://m.golangroadmap.com/#/sharevideo?id=30",
              },
            ],
          },
        ],
      },
      valineOptions: {
        appId: "kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz",
        appKey: "nscorEe7TaRyn1DBFNicKsWH",
        placeholder:
          "欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。",
        path: window.location.pathname,
      },
    };
  },

  created () {
    this.id = this.$route.query.id;
    this.valineOptions.path = "/#/collectiondetail&id=" + this.id;
    //设置邀请码
    if (this.$route.query.code !== undefined) {
      window.localStorage.setItem("code", this.$route.query.code);
    }
    this.initData();
  },
  methods: {
    //分享
    onSelect (shareOption) {
      if (shareOption.name == "分享海报") {
        var shareImage =
          "https://image-1302243118.cos.ap-beijing.myqcloud.com/share/collection_" +
          this.id +
          ".jpg";

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [shareImage],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({
            message: "暂无海报,请选择微信分享",
            theme: "round-button",
            confirmButtonColor: "#0af",
          });
        };
      } else {
        Dialog({
          message: "合集信息链接已复制到剪切板，前往微信分享给朋友吧！",
          theme: "round-button",
          confirmButtonColor: "#0af",
        });
        this.showShare = false;
        let container = this.$refs.container;
        var invitationCode =
          window.localStorage.getItem("invitationCode") == null
            ? "Go"
            : window.localStorage.getItem("invitationCode");
        var shareUrl =
          "这里有一个不错的Go合集分享给你：https://m.golangroadmap.com/#/collectiondetail?id=" +
          this.id +
          "&code=" +
          invitationCode +
          "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。";
        this.$copyText(shareUrl, container);
      }
    },
    //打开分享
    onClickShare () {
      this.showShare = true;
    },

    initData () {
      axios
        .get("https://api.golangroadmap.com/collection/m/detail?id=" + this.id)        
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            
            this.collectionDetail = response.data.data;
          } else {
            console.log(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickLeft () {
      this.$router.push("collection");
    },
    goto (val) {
      
      if(val === undefined){
        return;
      }

      if (this.collectionDetail.subscribeStatus == 1) {
        // //修改跳转（）
        // this.$router.push(val);

        axios
        .get("https://api.golangroadmap.com/collection/chapter/detail?id=" + val)        
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            if(response.data.data.conent === undefined){
              response.data.data.conent = ""
            }
            Object.assign(this.collectionChapterContent, response.data.data);
            this.chapterShow = true
          } else {
            console.log(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });





      } else if (this.collectionDetail.subscribeStatus == 0) {
        Dialog.confirm({
          title: "提示",
          message:
            "您还没有登录，请先登录后使用Go合集功能，点击确定前往登录页。",
        })
          .then(() => {
            this.$router.push("login");
          })
          .catch(() => {
            // on cancel
          });
      } else if (this.collectionDetail.subscribeStatus == 3) {
        Dialog.confirm({
          title: "提示",
          message: "您的会员已到期，如希望继续保留合集观看权益，还请续费支持。",
        })
          .then(() => {
            //跳转vip办理页面
            //this.$router.push("home");
            this.$router.push("vip");
          })
          .catch(() => {
            // on cancel
          });
      } else if (this.collectionDetail.subscribeStatus == 2) {
        Dialog.confirm({
          title: "提示",
          message: "暂未订阅，请先完成订阅。",
        })
          .then(() => {
            // this.$router.push('home');
          })
          .catch(() => {
            // on cancel
          });
      } else if (this.collectionDetail.subscribeStatus == 4) {
        this.buyShow = true;
      }
    },
    subscribeCollection () {
      if (this.collectionDetail.subscribeStatus == 0) {
        Dialog.confirm({
          title: "提示",
          message:
            "您还没有登录，请先登录后使用Go合集功能，点击确定前往登录页。",
        })
          .then(() => {
            this.$router.push("login");
          })
          .catch(() => {
            // on cancel
          });
      } else if (
        this.collectionDetail.subscribeStatus == 2
      ) {
        axios
          .get(
            "https://api.golangroadmap.com/collection/subscribe?id=" + this.id
          )
          .then((response) => {
            if (response.status == "200" && response.data.code == "100") {
              this.collectionDetail.subscribeStatus = 1;
              Dialog.confirm({
                title: "提示",
                message: "订阅成功。",
              })
                .then(() => { })
                .catch(() => {
                  // on cancel
                });
            } else if (
              response.status == "200" &&
              response.data.code == "236"
            ) {
              Dialog.confirm({
                title: "提示",
                cancelButtonText: "办理会员",
                message: response.data.msg + "分享此合集到微信，自带你的邀请码。",
              })
                .then(() => {
                  
                 })
                .catch(() => {
                  this.$router.push("vip");
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        this.collectionDetail.subscribeStatus == 4
      ) {
        this.buyShow = true;
      }
    },
    beforeCloseFn (action, done) {
      // 执行一些校验或处理逻辑
      if (action === "confirm") {
        // 如果点击了确认按钮，则继续关闭对话框
        if (this.coupon === "") {
          Toast.fail("激活码不能为空！");
          setTimeout(done, 1000);
          return;
        }

        this.orderClick();
        setTimeout(done, 1000);
      } else {
        // 如果点击了取消按钮，则阻止对话框关闭
        this.$router.push("vip");
        done();
      }
    },
    orderClick () {
      const params = new URLSearchParams();
      params.append("collectionId", this.id);
      params.append("coupon", this.coupon);
      axios
        .post("https://api.golangroadmap.com/collection/pay/subscribe", params)
        .then((response) => {
          if (response.status == "200" && response.data.code == "237") {
            this.collectionDetail.subscribeStatus = 1;
            Dialog.confirm({
              title: "提示",
              message: "订阅成功。",
            })
              .then(() => { })
              .catch(() => {
                // on cancel
              });
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less">
.collection_desc {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 2px;
  height: 80px;
}
.resume-item {
  padding: 5px 16px;
  font-size: 14px;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
